<template>
  <div class="help_content">
    <!-- 内容 -->
    <el-breadcrumb v-if="index.indexOf('5_1') > -1" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">{{ lastPage=='lastPage'?'上一页':'帮助中心' }}</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">公告中心</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb v-else separator-class="el-icon-arrow-right">
      <el-breadcrumb-item style="cursor: pointer !important;"><span style="color: #AB54DB;font-weight: bold;" @click="goback()">帮助中心</span></el-breadcrumb-item>
      <el-breadcrumb-item style="color: #72777E">新手教程</el-breadcrumb-item>
    </el-breadcrumb>

    <span v-if="index.indexOf('5_1') > -1" class="content_title">公告</span>
    <span v-else class="content_title">新手教程</span>

    <div v-if="index.indexOf('5_1')>-1" class="content_notice_list" style="margin-top: 30px">
      <span style="display: block;margin-bottom: 30px;cursor: pointer" v-for="(item, i) in new_activities" @click="gotoContentNotice(item)">{{ i+1 }}. {{ item.brief }} >><span style="margin-left: 20px;color: #72777E">{{ item.date }}</span></span>
    </div>

    <div v-else class="content_list" style="margin-top: 30px">
      <span style="display: block;margin-bottom: 30px;cursor: pointer" v-for="(item, i) in common_problem" @click="gotoContent(item)">{{ i+1 }}. {{ item.title }} >><img v-if="item.index==5" style="display: inline-block;margin-left: 20px;vertical-align: middle" src="../../assets/img/help/video_icon.svg"/></span>
    </div>

    <div style="display: block">
      <my_video v-if="IsShow" ref="child" :src="src_url" :showVideo="showVideo" :showVideo_m="showVideo_m" :poster_url="poster_url" :showBtn1="showBtn"></my_video>
    </div>
  </div>
</template>

<script>
import My_video from "../common/my_video";
export default {
  name: "help_content_two",
  components: {My_video},
  data() {
    return {
      common_problem: [
        {index: 1, title: '新用户开通绑定流程'},
        {index: 5, title: '币安API绑定教程', src_url: 'https://felton-oss.oss-cn-beijing.aliyuncs.com/video/bind-bn-api.mp4', cover: require("../../assets/img/help/cover/bn_cover.jpg")},
        {index: 6, title: '欧易API绑定教程', src_url: 'https://felton-oss.oss-cn-beijing.aliyuncs.com/video/bind-ok-api.mp4'},
        {index: 7, title: '秒懂黄金三角套利交易' },
        {index: 8, title: '秒懂BB交易' },
        {index: 3, title: '秒懂UB交易' },
        {index: 2, title: '秒懂网格策略'},
        {index: 4, title: '秒懂马丁策略'},
        {index: 9, title: '欧易账户合约量化开通权限教程'},
        {index: 10, title: '秒懂对冲合约交易'},
      ],
      new_activities: [
        {index: 6, page: '5_1_6', brief: '关于返佣系统的调整和间接推荐奖下架公告', date: '2022-06-07'},
        {index: 5, page: '5_1_5', brief: '关于清退中国大陆地区存量用户的再次公告', date: '2022-05-31'},
        {index: 4, page: '5_1_4', brief: '关于币安下架BTT含有此代币的币安机器人需重新设置的公告', date: '2022-04-01'},
        {index: 3, page: '5_1_3', brief: '关于因币安的挂单限制不建议在不同策略中设置相同的交易对', date: '2022-04-01'},
        {index: 2, page: '5_1_2', brief: '关于充值功能及补充燃料费方式调整的公告', date: '2022-03-31'},
        {index: 1, page: '5_1_1', brief: '关于欧易部分币对交易货币数量精度调整的公告', date: '2022-03-24'},
      ],
      index: '',

      showVideo: false,
      showVideo_m: false,
      src_url: '',
      poster_url: '',
      showBtn: false,
      IsShow: false,
      lastPage: '',
    }
  },

  activated(){
    this.index = this.$route.query.titleNum
    this.lastPage = this.$route.query.lastPage
  },

  created() {
  },

  mounted() {
    if (this.$util._isMobile()) {
      this.showVideo = false
      this.showVideo_m = false
    } else {
      this.showVideo = false
      this.showVideo_m = false
    }
  },

  methods:{
    gotoContent(item){
      if (item.index == 5){
        this.IsShow = true
        this.$nextTick(()=>{
          this.src_url = item.src_url
          this.poster_url = item.cover
          this.showVideoM()
        })
      } else {
        this.$router.push('/helpContent?titleNum='+item.index)
      }
    },

    gotoContentNotice(item){
      this.$router.push('/helpContent?titleNum='+item.page)
    },

    showVideoM(){
      if (this.$util._isMobile()){
        this.showVideo = false
        this.showVideo_m = true
        this.$refs.child.showVideoM()
      } else{
        this.showVideo = true
        this.showVideo_m = false
        this.showBtn = false
        this.$refs.child.open_video()
      }
    },

    goback(){
      this.$router.go(-1)
    }
  },

  destroyed() {
    console.log(this.index)
    this.index = ''
  }
}
</script>

<style scoped>

span {
  color: #1E2329;
}

>>>.el-breadcrumb__inner{
  color: #AB54DB;
}

>>>.el-breadcrumb__item:last-child .el-breadcrumb__inner{
  color: #72777E;
}

@media screen and (max-width: 900px) {
  .help_content {
    /*width: 100%;*/
    margin-top: -27px;
    min-height: 450px;
    padding: 0 16px
  }

  .content_list {
    column-count: 1;
  }

  .content_title {
    display: block;
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500
  }

  .auto_list {
    column-count: 3;
    text-align: center;
  }

  .el-container {
    display: block;
  }
}

@media screen and (min-width: 900px) {
  .content_list {
    /*column-count: 4;*/
  }

  .content_list span{
    display: inline-block !important;
    width: 25%;
  }

  .auto_list {
    column-count: 6;
    text-align: center;
  }

  .help_content {
    width: 1200px;
    min-height: 760px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 0 16px
  }

  .content_title {
    display: block;
    font-size: 26px;
    font-weight: 500;
    margin-top: 30px;
  }

}

.content_notice_list {
  column-count: 1;
}

</style>
