<template>
  <div>
    <div v-if="showVideo" :class="[showVideo_m?'app1_m':'app1']" id="app1" style="text-align: center">
      <div :class="[showVideo_m ? 'app2_m': 'app2']" style="z-index: 99999">
        <el-button :class="[showVideo_m?'bt-close-m':'bt-close']" style="" icon="el-icon-close" @click="closed_video()"></el-button>
      </div>
      <video id="video" style="display:block;width: 300px;max-height: 560px;position: unset;z-index: 99;background-color: #1E2329;box-shadow: 5px 5px 9px #888888;" controls :poster="poster_url">
        <source :src="src">
      </video>
    </div>
    <div v-if="!showVideo && !showVideo_m && showBtn1" id="bt_rideo" style="border-radius: 50%;cursor: pointer" class="message_icon" @click="open_video()">
      <img src="../../assets/img/video.svg" style="display: block;width: 23px; margin: 10px auto 0;"></img>
    </div>
  </div>
</template>

<script>
export default {
  name: "my_video",

  props:{
    src: {type: String,default:'auto'},
    poster_url: {type: String,default:'auto'},
    showBtn1: {type: Boolean,default:true},
  },

  data() {
    return {
      showVideo: true,
      showVideo_m: false,
    }
  },

  watch:{
    showVideo_m(val){
      this.showVideo_m = val
    },
    showVideo(val){
      this.showVideo = val
    },
  },

  mounted() {
    if (this.$util._isMobile()) {
      this.showVideo = false
      this.showVideo_m = true
    } else {
      this.showVideo = true
      this.showVideo_m = false
    }

    //获取元素  拖动div元素
    var dv = document.getElementById('app1');
    var x = 0;
    var y = 0;
    var l = 0;
    var t = 0;
    var isDown = false;
    //鼠标按下事件
    dv.onmousedown = function(e) {
      //获取x坐标和y坐标
      x = e.clientX;
      y = e.clientY;

      //获取左部和顶部的偏移量
      l = dv.offsetLeft;
      t = dv.offsetTop;
      //开关打开
      isDown = true;
      //设置样式
      dv.style.cursor = 'default';
    }
    //鼠标移动
    window.onmousemove = function(e) {
      if (isDown == false) {
        return;
      }
      //获取x和y
      var nx = e.clientX;
      var ny = e.clientY;
      //计算移动后的左偏移量和顶部的偏移量
      var nl = nx - (x - l);
      var nt = ny - (y - t);

      dv.style.left = nl + 'px';
      dv.style.top = nt + 'px';
    }
    //鼠标抬起事件
    dv.onmouseup = function() {
      //开关关闭
      isDown = false;
      dv.style.cursor = 'move';
    }
  },

  methods:{
    closed_video() {
      this.showVideo = false
      if (this.showVideo_m == true) return
      if (this.showBtn1){
        this.$nextTick(() => {
          document.getElementById('bt_rideo').className = 'message_icon'
          document.getElementById('bt_rideo').style.left='unset'
          document.getElementById('bt_rideo').style.top='unset'
        })
      }
    },
    open_video() {
      this.showVideo = true
      this.$nextTick(() => {
        document.getElementById('app1').style.inset = 'unset'
        document.getElementById('app1').style.right = '2%'
        document.getElementById('app1').style.bottom = '2%'
      })
    },
    showVideoM(){
      this.showVideo = true
      var that = this
      this.$nextTick(() => {
        document.getElementById('video').style.display = 'inline-block'
        document.getElementById('video').style.margin = '120px auto 0'
        document.getElementById('video').autoplay = 'autoplay'
        // that.fullScreen()
      })
    },
    Screen (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.oRequestFullscreen) {
        element.oRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      } else {
        var docHtml = document.documentElement;
        var docBody = document.body;
        var videobox = document.getElementById('video');
        var cssText = 'width:100%;height:100%;overflow:hidden;';
        docHtml.style.cssText = cssText;
        docBody.style.cssText = cssText;
        videobox.style.cssText = cssText+';'+'margin:0px;padding:0px;';
        document.IsFullScreen = true;
        // screen.orientation.lock('portrait');
      }
    },

    fullScreen() {
      let element = document.getElementById('video')
      this.Screen(element)
    },
  },
}
</script>

<style scoped>

.message_icon {
  position: fixed;
  bottom: 2%;
  right: 2%;
  z-index: 888;
  background: #AB54DB;
  width: 44px;
  height: 44px;
  box-shadow: 0px 2px 4px 0px rgba(44, 117, 255, 0.2), 0px 4px 8px 0px rgba(44, 117, 255, 0.1);
}

.bt-close {
  position: absolute;
  z-index: 999;
  right: 0;
  border: unset;
  background-color: transparent;
  font-size: 26px;
  padding: 5px;
}

.bt-close-m{
  position: absolute;
  z-index: 99999;
  right: 15px;
  border: unset;
  background-color: rgba(102, 102, 102, .8);
  font-size: 26px;
  padding: 5px;
  color: white;
  border-radius: 50%;
}

.app1 {
  position: fixed;
  right: 2%;
  bottom: 2%;
}
.app1_m {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.app2 {
  display: block;
  position: relative;
  width: 300px;
  margin-bottom: 40px;
}

.app2_m {
  display: block;
  position: absolute;
  top: 70px;
  right: 31px;
}

</style>
